import { VariableVisualAttributes } from 'product_modules/api/Core/VariablesApi';
import { VisualDataType } from 'product_modules/enums/VisualDataType';

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  avatarId?: string;
  active?: boolean;
}

export enum AddressValueKey {
  StreetName = 'street_name',
  StreetNumber = 'street_number',
  UnitNumber = 'unit_number',
  Sublocality = 'sublocality',
  City = 'city',
  StateOrProvince = 'state_or_province',
  ZipOrPostalCode = 'zip_or_postal_code',
  Country = 'country',
}

export interface AddressValue {
  [AddressValueKey.StreetNumber]?: string;
  [AddressValueKey.StreetName]?: string;
  [AddressValueKey.UnitNumber]?: string;
  [AddressValueKey.Sublocality]?: string;
  [AddressValueKey.City]?: string;
  [AddressValueKey.StateOrProvince]?: string;
  [AddressValueKey.ZipOrPostalCode]?: string;
  [AddressValueKey.Country]?: string;
}

export interface BaseFieldComponentAttributes {
  id: string;
  label: string;
  visualDataType: VisualDataType;
  visualAttributes: VariableVisualAttributes;
}

export interface DefaultFieldComponent extends BaseFieldComponentAttributes {
  virtual?: false;
}

export interface VirtualFieldComponent extends BaseFieldComponentAttributes {
  virtual: true;
  transform: (value: VariableValue) => VariableValue;
  toFormula: (variable: string) => string;
}

export type FieldComponent = DefaultFieldComponent | VirtualFieldComponent;

export type TableRowValue = Record<string, BasicVariableValue> & {
  _id: string;
};
export type TableValue = TableRowValue[];

export type BasicVariableValue = number | string | boolean | AddressValue | null | undefined;
export type ComplexVariableValue = TableValue;
export type VariableValue = BasicVariableValue | ComplexVariableValue;

export type ISystemVariableValue =
  | string
  | number
  | boolean
  | Record<string, unknown>
  | Array<unknown>
  | null
  | undefined;

export interface IRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IImageParams {
  originalFileName: string;
  cropRect: IRect;
}

export interface IImageParamsWithId extends IImageParams {
  id: string;
}

export * from './LoanOriginationSystem/Types';
