import createWorkerHook, { ModuleMethods } from 'product_modules/hooks/createWorkerHook';
import { Worker } from 'threads';
import { FormLayoutData } from 'product_modules/api/Types';
// @ts-expect-error it don't want .ts
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerURL from 'threads-plugin/dist/loader?name=calculations-worker!../workers/CalculationsLogicWorker';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';

interface ICalculationsLogicWorker extends ModuleMethods {
  runCalculationsLogic<CalculationType extends BaseCalculation>(
    calculations: Array<CalculationType>,
    data: Record<string, unknown>,
  ): Promise<FormLayoutData>;
}

const useCalculationsLogicWorker = createWorkerHook<ICalculationsLogicWorker>(() => {
  return new Worker(workerURL);
});

export default useCalculationsLogicWorker;
