import { useMemo } from 'react';
import { ISystemVariableValue } from 'product_modules/api/Types';
import { applicationSystemVariables } from 'config/system-variables-config';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';

export const useApplicationSystemContext = (application: IApplicationViewModel) => {
  return useMemo(() => {
    return applicationSystemVariables.reduce((context, applicationSystemVariable) => {
      context[applicationSystemVariable.id] = applicationSystemVariable.extractValue(application);

      return context;
    }, {} as Record<string, ISystemVariableValue>);
  }, [application]);
};

export default useApplicationSystemContext;
