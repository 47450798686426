const SYSTEM_INPUT_PREFIX = '$' as const;

export const isSystemInputName = (input: string): boolean => {
  return input.startsWith(SYSTEM_INPUT_PREFIX);
};

export const getCategorizedInputNames = (inputNames: string[]) => {
  return inputNames.reduce((categories, inputName) => {
    if (isSystemInputName(inputName)) {
      categories.systemInputNames.push(inputName);
    } else {
      categories.customInputNames.push(inputName);
    }

    return categories;
  }, {
    customInputNames: [] as string[],
    systemInputNames: [] as string[],
  });
};
