import stringTemplate from 'utils/stringTemplate';

const formatValueByType = (value: unknown, arraySeparator = '\n'): string => {
  if (value === null || value === undefined) {
    return '';
  }

  if (Array.isArray(value)) {
    return (value as Array<unknown>)
      .map((item) => formatValueByType(item, ', '))
      .join(arraySeparator);
  }

  if (typeof value === 'object') {
    return formatValueByType(Object.values(value as object), ', ');
  }

  return String(value);
};

const formatValues = (context: Record<string, unknown>) => {
  return Object.keys(context).reduce((result, key) => {
    result[key] = formatValueByType(context[key]);

    return result;
  }, {} as Record<string, unknown>);
};

const renderStringTemplate = (body: string, context: Record<string, unknown>) => {
  return stringTemplate(body, formatValues(context));
};

export default renderStringTemplate;
