import DateTimeUtils from 'product_modules/utils/dateUtils';
import { convertUnknownValueToString } from 'product_modules/utils/coerceUtils';

const UNIVERSAL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ISO_STRING_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const isValidDateTimeValue = (value: unknown): value is string => {
  if (typeof value !== 'string') {
    return false;
  }

  return DateTimeUtils.parse(value, ISO_STRING_DATE_FORMAT).isValid();
};

const formatUnknownValueToUniversalDateTime = (value: unknown) => {
  if (!isValidDateTimeValue(value)) {
    return convertUnknownValueToString(value);
  }

  return DateTimeUtils.parse(new Date(value)).format(UNIVERSAL_DATE_FORMAT);
};

export default formatUnknownValueToUniversalDateTime;
