export const STRING_TEMPLATE_REGEX = /{(\$?[0-9a-zA-Z_]+)}/g;

const OPEN_CURLY_BRACKET = '{';
const CLOSE_CURLY_BRACKET = '}';

const stringTemplate = (template: string, context: Record<string, unknown>) => {
  return template.replace(STRING_TEMPLATE_REGEX, (match, item: string, index: number) => {
    if (template[index - 1] === OPEN_CURLY_BRACKET && template[index + match.length] === CLOSE_CURLY_BRACKET) {
      return item;
    } else {
      const result = Object.prototype.hasOwnProperty.call(context, item) ? context[item] : null;

      if (result === null || result === undefined) {
        return '';
      }

      return result as string;
    }
  });
};

export default stringTemplate;
