import React, { FC } from 'react';
import clsx from 'clsx';
import { ISystemVariableValue, VariableValue } from 'product_modules/api/Types';
import useTemplateRenderingContext from 'hooks/useTemplateRenderingContext';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ITextElementConfig } from 'api/digifi/portal-page-elements';
import renderStringTemplate from 'utils/renderStringTemplate';
import styles from './SubHeaderTextElement.module.scss';

interface ISubHeaderTextElementProps {
  config: ITextElementConfig;
  context: Record<string, VariableValue | ISystemVariableValue>;
  containerClassName?: string;
}

const DEFAULT_SUB_HEADER_VALUE = 'Sub-Header Text';

const SubHeaderTextElement: FC<ISubHeaderTextElementProps> = ({
  config,
  context,
  containerClassName,
}) => {
  const templateRenderingContext = useTemplateRenderingContext(config.text, context);

  if (!templateRenderingContext) {
    return (
      <div className={containerClassName}>
        <SkeletonText lineHeight="36px" width="280px" height="28px" color="primary20" className={styles.skeletonDesktop} />
        <SkeletonText lineHeight="36px" width="280px" height="28px" color="primary20" className={styles.skeletonTablet} />
        <SkeletonText lineHeight="28px" width="240px" height="22px" color="primary20" className={styles.skeletonMobile} />
      </div>
    );
  }

  return (
    <div className={clsx(styles.subHeaderText, containerClassName)} style={{ textAlign: config.alignment }}>
      {renderStringTemplate(config.text, templateRenderingContext) || DEFAULT_SUB_HEADER_VALUE}
    </div>
  );
};

export default SubHeaderTextElement;
