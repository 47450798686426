import React, { FC } from 'react';
import clsx from 'clsx';
import { ISystemVariableValue, VariableValue } from 'product_modules/api/Types';
import useTemplateRenderingContext from 'hooks/useTemplateRenderingContext';
import SkeletonText from 'components/digifi-wrappers/SkeletonText';
import { ITextElementConfig } from 'api/digifi/portal-page-elements';
import renderStringTemplate from 'utils/renderStringTemplate';
import styles from './BodyTextElement.module.scss';

interface IBodyTextElementProps {
  config: ITextElementConfig;
  context: Record<string, VariableValue | ISystemVariableValue>;
  containerClassName?: string;
}

const DEFAULT_BODY_VALUE = 'Body Text';

const BodyTextElement: FC<IBodyTextElementProps> = ({
  config,
  context,
  containerClassName,
}) => {
  const templateRenderingContext = useTemplateRenderingContext(config.text, context);

  const renderSkeletonContent = (linesCount: number) => (
    <>
      {Array.from({ length: linesCount }).map((_, index) => (
        <SkeletonText key={index} lineHeight="20px" width={index % 2 ? '95%' : '100%'} height="14px" color="primary6" />
      ))}
      <SkeletonText lineHeight="20px" width="80%" height="14px" color="primary6" />
    </>
  );

  if (!templateRenderingContext) {
    return (
      <div className={containerClassName}>
        <div className={styles.skeletonDesktop}>
          {renderSkeletonContent(8)}
        </div>
        <div className={styles.skeletonTablet}>
          {renderSkeletonContent(10)}
        </div>
        <div className={styles.skeletonMobile}>
          {renderSkeletonContent(22)}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.bodyText, containerClassName)} style={{ textAlign: config.alignment }}>
      {renderStringTemplate(config.text, templateRenderingContext) || DEFAULT_BODY_VALUE}
    </div>
  );
};

export default BodyTextElement;
