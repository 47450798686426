import { keyBy } from 'lodash';
import { IApplicationViewModel } from 'api/digifi/ApplicationsApi';
import { ISystemVariable } from 'product_modules/api/Core/VariablesApi';
import { ISystemVariableValue } from 'product_modules/api/Types';
import { convertUnknownValueToString } from 'product_modules/utils/coerceUtils';
import formatDisplayId from 'product_modules/utils/userIdFormat';
import formatUnknownValueToUniversalDateTime from 'product_modules/utils/valueFormatters/formatUnknownValueToUniversalDateTime';

interface IApplicationSystemVariable extends ISystemVariable {
  extractValue: (application: IApplicationViewModel) => ISystemVariableValue;
}

export const applicationSystemVariables: IApplicationSystemVariable[] = [{
  id: '$application_id',
  name: 'Application Id',
  extractValue: (application) => application.id,
}, {
  id: '$application_display_id',
  name: 'Application Display Id',
  format: (displayId) => {
    return typeof displayId === 'string' ? formatDisplayId(displayId as string) : convertUnknownValueToString(displayId);
  },
  extractValue: (application) => application.displayId,
}, {
  id: '$application_product_id',
  name: 'Application Product Id',
  extractValue: (application) => application.productId,
}, {
  id: '$application_rejected_date',
  name: 'Rejected Date',
  format: formatUnknownValueToUniversalDateTime,
  extractValue: (application) => {
    return application.rejectedAt ? new Date(application.rejectedAt).toISOString() : undefined;
  },
}, {
  id: '$application_approved_date',
  name: 'Application Approved Date',
  format: formatUnknownValueToUniversalDateTime,
  extractValue: (application) => {
    return application.approvedAt ? new Date(application.approvedAt).toISOString() : undefined;
  },
}, {
  id: '$application_created_date',
  name: 'Application Created Date',
  format: formatUnknownValueToUniversalDateTime,
  extractValue: (application) => new Date(application.createdAt).toISOString(),
}, {
  id: '$application_status_id',
  name: 'Application Status Id',
  extractValue: (application) => application.status.id,
}, {
  id: '$application_borrower_id',
  name: 'Application Borrower Id',
  extractValue: (application) => application.borrowerId,
}, {
  id: '$application_borrower_type',
  name: 'Application Borrower Type',
  extractValue: (application) => application.borrowerType,
}, {
  id: '$application_intermediary_id',
  name: 'Application Intermediary Id',
  extractValue: (application) => application.intermediaryId,
}, {
  id: '$application_decline_reasons',
  name: 'Application Decline Reasons',
  extractValue: (application) => application.declineReasons,
}, {
  id: '$application_coborrower_ids',
  name: 'Application Coborrower Ids',
  extractValue: (application) => application.coborrowerIds,
}, {
  id: '$application_coborrower_types',
  name: 'Application Coborrower Types',
  extractValue: (application) => application.coborrowerTypes,
}];

export const allAvailableSystemVariables: ISystemVariable[] = [
  ...applicationSystemVariables,
];

export const systemVariablesConfig = keyBy(allAvailableSystemVariables, 'id');
