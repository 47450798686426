import { ISystemVariable } from 'product_modules/api/Core/VariablesApi';
import { ISystemVariableValue } from 'product_modules/api/Types';
import { convertUnknownValueToString } from 'product_modules/utils/coerceUtils';

export const formatSystemVariableValue = (value: ISystemVariableValue, variable: ISystemVariable) => {
  if (variable.format) {
    return variable.format(value);
  }

  return convertUnknownValueToString(value);
};
