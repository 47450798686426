import { useMemo, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormLayoutData, VariableValue } from 'product_modules/api/Types';
import { isConfigurableFormDataChanged } from 'product_modules/components/ConfigurableForm/utils';
import useCalculationsLogicWorker from './useCalculationsLogicWorker';
import useDataByHashKey from './useDataByHashKey';
import useOrderedCalculations from './useOrderedCalculations';
import { BaseCalculation } from 'product_modules/api/LoanOriginationSystem/Base/CalculationsApi';

const RECOMPUTE_DELAY = 500;
const DEFAULT_CALCULATED_DATA = {};

const useCalculationsLogic = <CalculationType extends BaseCalculation>(
  calculations: Map<string, CalculationType> | null,
  data: Record<string, unknown>,
  disableCalculating?: boolean,
) => {
  const calculationsLogicWorker = useCalculationsLogicWorker(disableCalculating);

  const orderedCalculations = useOrderedCalculations(calculations ? Array.from(calculations.values()) : null);

  const [calculatedData, setCalculatedData] = useDataByHashKey<FormLayoutData>(orderedCalculations);

  const isDataWasCalculated = !!calculatedData;

  const runCalculationsLogic = useCallback(async (calculationLogicData: Record<string, unknown>) => {
    if (!calculationsLogicWorker || !orderedCalculations) {
      return;
    }

    if (!orderedCalculations.length) {
      setCalculatedData(DEFAULT_CALCULATED_DATA);

      return;
    }

    const updatedCalculatedData = await calculationsLogicWorker.runCalculationsLogic(
      orderedCalculations,
      calculationLogicData,
    );

    setCalculatedData((previousCalculatedData) => {
      return !previousCalculatedData || isConfigurableFormDataChanged(previousCalculatedData, updatedCalculatedData)
        ? updatedCalculatedData
        : previousCalculatedData;
    });
  }, [calculationsLogicWorker, orderedCalculations]);

  const debouncedRunCalculationsLogic = useMemo(() => {
    return isDataWasCalculated ? debounce(runCalculationsLogic, RECOMPUTE_DELAY) : runCalculationsLogic;
  }, [runCalculationsLogic, isDataWasCalculated]);

  useEffect(() => {
    if (disableCalculating) {
      return;
    }

    debouncedRunCalculationsLogic(data);
  }, [orderedCalculations, calculationsLogicWorker, data, disableCalculating]);

  return useMemo(() => {
    if (disableCalculating) {
      return data;
    }

    if (!calculatedData) {
      return null;
    }

    if (!Object.values(calculatedData).length) {
      return data;
    }

    return { ...data, ...calculatedData };
  }, [data, calculatedData, disableCalculating]);
};

export default useCalculationsLogic;
